<template>
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <CustomerNavbar></CustomerNavbar>
            </div>
            <div class="col-md-9">
                <div class="panel panel-default">
                    <div class="panel-heading dash-head">Ordered Information</div>
                    <div class="panel-body">

                      <table class="table" >
                        <thead class="text-center">
                        <th>Order Date</th>
                        <th>Order Id</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th style="text-align: right">Action</th>
                        </thead>
                        <tbody class="text-left">
                        <tr v-for="row in customerSummery.orders" :key="row.id">
                          <td>{{ row.order_date }}</td>
                          <td>{{ row.orderID }}</td>
                          <td>{{ row.order_items[0].quantity }}</td>
                          <td>{{ row.total_amount }}</td>
                          <td :class="row.order_status===0?'cancel':
                                                        row.order_status===1?'pending':
                                                        row.order_status===2?'confirm':
                                                        row.order_status===3?'hold':
                                                        row.order_status===4?'shipped':
                                                        row.order_status===5?'delivered':
                                                        row.order_status===6?'partial':''">
                                                    <span>
                                                        {{ row.order_status===0?'cancel':
                                                        row.order_status===1?'Pending':
                                                            row.order_status===2?'confirm':
                                                                row.order_status===3?'Hold':
                                                                    row.order_status===4?'shipped':
                                                                        row.order_status===5?'delivered':
                                                                            row.order_status===6?'partial delivered':''
                                                      }}
                                                    </span>

                          </td>
                          <td style="text-align: right">
                            <button :disabled="row.order_status !== 1" class="btn btn-danger" type="button" @click="cancelOrder(row.id)">Cancel</button>
                            &nbsp;
                            <button class="btn btn-success" type="button" @click="viewDetails(row.id)">View</button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";
    import CustomerNavbar from './CustomerNavbar'
    export default {
        name:"Order",
      data: () => ({
        currentPage: 1,
        totalPages: 0
      }),
        components: {
            CustomerNavbar
        },
        computed: {
            ...mapGetters(["me", "customerSummery","loading"]),
        },
      mounted() {
        this.$store.dispatch("getcustomerSummery");
      },
      methods :{
            viewDetails(orderId){
                this.$router.push('/order_details/'+ orderId).catch(() => {})
            },
            cancelOrder(orderid){
                this.$store.dispatch('cancelOrder', orderid);
                this.$store.dispatch('getcustomerSummery');
                this.$store.dispatch('getcustomerSummery');
                //this.$toasted.success('Successfully Canceled.');
            }
        }
    }

</script>
<style scoped>
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  vertical-align: middle;
}
</style>